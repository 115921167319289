var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modalDataEdit.showModal)?_c('BaseModal',[_c('ApplicationFormEditModal',{attrs:{"headline":_vm.modalDataEdit.modalHeadline,"modalType":_vm.modalDataEdit.modalType,"existingItemModal":_vm.modalDataEdit.existingItem},on:{"close":function($event){return _vm.closeModal(_vm.modalDataEdit)}}})],1):_vm._e(),(_vm.modalDataDelete.showModal)?_c('BaseModal',[_c('ApplicationFormDeleteModal',{attrs:{"headline":_vm.modalDataDelete.modalHeadline,"modalType":_vm.modalDataDelete.modalType,"existingItemModal":_vm.modalDataDelete.existingItem},on:{"close":function($event){return _vm.closeModal(_vm.modalDataDelete)}}})],1):_vm._e(),_c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.applicationForms,"search":_vm.search,"sort-by":"updated","sort-desc":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"},attrs:{"data-cy":"titleApplicationForm"}},[_vm._v(" Påmeldingsskjema ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Lag nytt påmeldingsskjema til kurs","icon":"mdi-plus","dataCy":"open-new-application-form-popup"},on:{"handleClick":_vm.openNewApplicationPage}})]},proxy:true},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"item.isVocationalSchool",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Ja" : "Nei")+" ")]}},{key:"item.updated",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatRelative(value))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"openDisplayApplicationPage"},on:{"click":function($event){return _vm.openDisplayApplicationPage(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-clipboard-text-search-outline ")])]}}],null,true)},[_c('span',[_vm._v("Vis "+_vm._s(item.name))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"openEditApplicationForm"},on:{"click":function($event){return _vm.openEditApplicationForm(item, item.name)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger "+_vm._s(item.name))])]),(item.appliedInCourses && item.appliedInCourses.length <= 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"openDeleteApplicationForm"},on:{"click":function($event){return _vm.openDeleteApplicationForm(item, item.name)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Slett "+_vm._s(item.name))])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }